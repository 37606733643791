<script lang="ts">
import { defineAsyncComponent, onErrorCaptured } from 'vue';

const ZNotifications = defineAsyncComponent(() => import('@shared/ui/singletones/ZNotifications'));
const ZSubmitDialog = defineAsyncComponent(() => import('@shared/ui/singletones/ZSubmitDialog'));

export default {
  name: 'TheApp',
};
</script>

<script setup lang="ts">
import { Logger, type ILoggerMessage } from '@shared/libs/logger';
import { InternalError } from '@shared/libs/errors/InternalError';
import { VueWrapperForInternalError, getComponentNamesStack } from '@shared/libs/errors/VueWrapperForInternalError';

// Инициализация
import { useProvidersAtProject } from '~shared/providers';
import { pages } from './router/routes';

onErrorCaptured((err, instance, info) => {
  try {
    const componentsStack = getComponentNamesStack(instance);
    if (typeof componentsStack === 'string') {
      Logger.instance.error(new InternalError(componentsStack));
    }

    const vueWrapperError = new VueWrapperForInternalError(
      typeof componentsStack === 'string' ? null : componentsStack,
      info,
      err,
    );

    Logger.instance.error(vueWrapperError as unknown as ILoggerMessage);
    return false;
  } catch (handlingError) {
    const internalErrorForHandlingError = new InternalError(
      `Не удалось обработать ошибку с помощью VueWrapperForInternalError\nerror message: ${(handlingError as Error).message ?? err}`,
    );

    if (handlingError instanceof Error) {
      internalErrorForHandlingError.cause = handlingError.cause;
      internalErrorForHandlingError.name = handlingError.name;
      internalErrorForHandlingError.stack = handlingError.stack;
    }

    Logger.instance.error(internalErrorForHandlingError);

    const internalErrorForHookError = new InternalError();

    if (err instanceof Error) {
      internalErrorForHookError.message = err.message;
      internalErrorForHookError.cause = err.cause;
      internalErrorForHookError.name = err.name;
      internalErrorForHookError.stack = err.stack;
    } else {
      internalErrorForHookError.message = err;
    }
    Logger.instance.error(internalErrorForHookError);
  }
});

useProvidersAtProject(pages);
</script>

<template>
  <div>
    <Teleport to="body">
      <ZNotifications />
      <ZSubmitDialog />
    </Teleport>

    <VApp class="h-screen">
      <VNavigationDrawer>
        <VList>
          <VListItem title="Navigation drawer"></VListItem>
        </VList>
      </VNavigationDrawer>

      <VAppBar title="Application bar1"></VAppBar>

      <VMain>
        <RouterView #default="{ Component }">
          <VFadeTransition mode="out-in">
            <component :is="Component" />
          </VFadeTransition>
        </RouterView>
      </VMain>
    </VApp>
  </div>
</template>

<style scoped>
/* .main {
  height: 100dvh;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
} */
</style>
