import { defineStore } from 'pinia';
import { ref, readonly } from 'vue';

import { type ISignInRequest, api } from '../api';
import { OPERATION_STATUS } from '~shared/orc';

export const useSessionStore = defineStore('session', () => {
  const username = ref<string | null>(null);
  const signedIn = ref<null | boolean>(null);

  const sessionLoading = ref(false);
  const sessionDataLoaded = ref(false);

  const updateSignedInStatus = async () => {
    sessionLoading.value = true;
    const isAuthedResult = await api.isAuthed();
    signedIn.value = isAuthedResult.status === OPERATION_STATUS.SUCCESS;
    sessionLoading.value = false;
  };

  const signIn = async (body: ISignInRequest) => {
    sessionLoading.value = true;
    const result = await api.signIn(body);

    if (result.status === OPERATION_STATUS.SUCCESS) {
      signedIn.value = true;
      username.value = body.username;
    }

    sessionLoading.value = false;
    return result;
  };

  const logout = async () => {
    sessionLoading.value = true;
    const result = await api.logout();

    if (result.status === OPERATION_STATUS.SUCCESS) {
      signedIn.value = false;
      username.value = null;
    }

    sessionLoading.value = false;
    return result;
  };

  return {
    username: readonly(username),
    signedIn: readonly(signedIn),
    sessionLoading: readonly(sessionLoading),
    sessionDataLoaded,

    signIn,
    logout,
    updateSignedInStatus,
  };
});
