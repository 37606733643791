import type { RouteRecordRaw } from 'vue-router';
import { authGuard } from '@app/guards/auth';
import { onInitGuard } from '@app/guards/onInit';

const name = 'Auth';

export const route: RouteRecordRaw = {
  path: '/auth',
  name,
  component: () => import('@/pages/auth'),
  beforeEnter: [authGuard, onInitGuard],
  meta: {
    authed: false,
  },
  children: [],
};

export const page = {
  _name: name,
} as const;
