import type { RouteRecordRaw } from 'vue-router';
import { onInitGuard } from '@app/guards/onInit';

const name = 'Testing';

export const route: RouteRecordRaw = {
  path: '/testing',
  name,
  component: () => import('@/pages/testing'),
  beforeEnter: [onInitGuard],
  children: [],
};

export const page = {
  _name: name,
} as const;
