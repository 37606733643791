import type { NavigationGuard } from 'vue-router';
import { useNotificationStore } from '@shared/ui/singletones/ZNotifications';

export const notFoundGuard: NavigationGuard = async (to, from, next) => {
  const notificationStore = useNotificationStore();

  notificationStore.showSnackbar.message({
    title: 'Не найдена',
    text: 'Страница, на которую вы пытаетесь зайти, не существует',
  });

  next();
};
