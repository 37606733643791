import { createVuetify } from 'vuetify';
import { aliases } from 'vuetify/iconsets/mdi-svg';
import '@/shared/styles/libs/vuetify/main.scss';

import('@mdi/font/css/materialdesignicons.css');

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#fc5a03',
          secondary: '#5CBBF6',
          danger: '#EF5A6F',
        },
      },
    },
  },
});

export { vuetify };
