/* eslint-disable no-console */
import type { ILoggerMessage, ILoggerSerializedMessage } from './types';

const insertWhitespaceEveryThirdFromEnd = (input: string) => {
  let result = '';
  let count = 0;

  for (let i = input.length - 1; i >= 0; i--) {
    result = input[i] + result;
    count++;
    if (count % 3 === 0 && i !== 0) {
      result = ' ' + result;
    }
  }

  return result;
};

const LOGGER_MESSAGE_STYLES = {
  error: 'font-weight: bold; color: red; font-size: 14px;',
  warn: 'font-weight: bold; color: orange; font-size: 14px;',
  message: 'font-weight: bold; color: blue; font-size: 14px;',
} as const;

let logger: null | Logger = null;
export class Logger {
  private constructor() {}

  static get instance() {
    if (logger === null) {
      logger = new Logger();
    }

    return logger;
  }

  private _log(type: keyof typeof LOGGER_MESSAGE_STYLES, { title, message }: ILoggerSerializedMessage) {
    console.groupCollapsed(
      `%c${insertWhitespaceEveryThirdFromEnd(Date.now().toString())}:`,
      LOGGER_MESSAGE_STYLES[type],
      `${title}`,
    );

    if (Array.isArray(message)) {
      for (const msg of message) {
        console.log(...(Array.isArray(msg) ? msg : [msg]));
      }
    } else {
      console.log(message);
    }
    console.groupEnd();
  }

  error(message: ILoggerMessage) {
    this._log('error', message.serialize());
  }
  warn(message: ILoggerMessage) {
    this._log('warn', message.serialize());
  }
  message(message: ILoggerMessage) {
    this._log('message', message.serialize());
  }
}
