import { OPERATION_STATUS, createOrc, EStatusCodes } from '~shared/orc';
import { wrapHttpRequest } from '~shared/http';

import { type IOAuthEntity, fetcher } from '@shared/api';

export type ISignInRequest = IOAuthEntity<'ATH_SignInRequest'>;
export type ISignUpRequest = IOAuthEntity<'ATH_SignUpRequest'>;

const signIn = (body: ISignInRequest) =>
  wrapHttpRequest(async () => {
    const response = await fetcher.auth.POST('/api/auth/sign-in', { body });

    if (response.response.status === EStatusCodes.NO_CONTENT) {
      return createOrc({
        status: OPERATION_STATUS.SUCCESS,
      });
    } else if (response.response.status === EStatusCodes.BAD_REQUEST) {
      return createOrc({
        status: OPERATION_STATUS.ERROR,
        httpStatus: EStatusCodes.IM_A_TEAPOT,
        data: response.data,
      });
    }
  });

const signUp = (body: ISignUpRequest) =>
  wrapHttpRequest(async () => {
    const response = await fetcher.auth.POST('/api/auth/sign-up', { body });

    if (response.response.status === EStatusCodes.NO_CONTENT) {
      return createOrc({
        status: OPERATION_STATUS.SUCCESS,
      });
    }
  });

const logout = () =>
  wrapHttpRequest(async () => {
    const response = await fetcher.auth.POST('/api/auth/logout');

    if (response.response.status === EStatusCodes.NO_CONTENT) {
      return createOrc({
        status: OPERATION_STATUS.SUCCESS,
      });
    }
  });

const isAuthed = () =>
  wrapHttpRequest(async () => {
    const response = await fetcher.auth.GET('/api/auth/protected', { parseAs: 'text' });

    if (response.response.status === EStatusCodes.OK) {
      return createOrc({
        status: OPERATION_STATUS.SUCCESS,
      });
    }
  });

export const api = {
  signIn,
  signUp,
  logout,
  isAuthed,
};
