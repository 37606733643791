import type { RouteRecordRaw } from 'vue-router';

const name = 'Home';

export const route: RouteRecordRaw = {
  path: 'home',
  name,
  component: () => import('@/pages/home'),
  children: [],
};

export const page = {
  _name: name,
} as const;
