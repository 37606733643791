import type { onErrorCaptured } from 'vue';
import { InternalError } from './InternalError';
import type { ILoggerMessage } from '@shared/libs/logger';
import { SPLITTER } from '@shared/libs/logger';

export const getComponentNamesStack = (instance: Parameters<Parameters<typeof onErrorCaptured>['0']>['1']) => {
  if (instance === null) {
    return null;
  }

  try {
    const stack: (string | null)[] = [];
    type IComponent = { type: { name?: string | null }; parent: IComponent | null };
    let currentComponent: IComponent = instance.$ as IComponent;
    do {
      stack.push(currentComponent.type.name ?? null);
      if (currentComponent.parent) {
        currentComponent = currentComponent.parent;
      }
    } while (currentComponent.parent !== null);

    return stack;
  } catch (err) {
    return (err as Error).message ?? (err as string);
  }
};
export class VueWrapperForInternalError extends InternalError implements ILoggerMessage {
  private _error: Error;
  private _info: string;
  private _componentsStack: (string | null)[] | null;

  constructor(componentsStack: (string | null)[] | null, info: string, error: Error) {
    super();

    this._componentsStack = componentsStack;
    this._info = info;
    this._error = error;
  }

  serialize() {
    return {
      title: 'Получена ошибка внутри onErrorCaptured хука',
      message: `info: "${this._info}"
${SPLITTER}
componentsStack: "${this._componentsStack?.join(', ')}"
${SPLITTER}
error.message: "${this._error.message}"
${SPLITTER}
error.name: "${this._error.name}"
${SPLITTER}
error.constructor.name: "${this._error.constructor.name}"
${SPLITTER}
error.stack: "${this._error.stack}"
`,
    };
  }
}
