import { type NavigationGuard } from 'vue-router';

import { SessionModel } from '@/entities/session';
import { useNotificationStore } from '@shared/ui/singletones/ZNotifications';

import { pages } from '../routes';

export const authGuard: NavigationGuard = async (to, from, next) => {
  const sessionStore = SessionModel.useSessionStore();

  if (sessionStore.signedIn === null) {
    await sessionStore.updateSignedInStatus();
  }

  const notificationStore = useNotificationStore();

  if (sessionStore.signedIn === to.meta.authed) {
    next();
  } else if (typeof sessionStore.signedIn === 'boolean' && sessionStore.signedIn && !to.meta.authed) {
    next({ name: pages.root._name });
    notificationStore.showSnackbar.message({
      text: 'Будучи авторизованным вы не можете посещать неавторизованные разделы',
    });
  } else {
    next({ name: pages.auth._name });
  }
};
