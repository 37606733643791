import { defineStore } from 'pinia';

import { useToastStore } from './toastStore';
import { useSnackbarStore } from './snackbarStore';

export const useNotificationStore = defineStore('notification', {
  getters: {
    showToast: () => {
      const toastStore = useToastStore();
      return toastStore.showToast();
    },
    hideToast: () => {
      const toastStore = useToastStore();
      return toastStore.hideToast;
    },
    showSnackbar: () => {
      const snackbarStore = useSnackbarStore();
      return snackbarStore.showSnackbar();
    },
    hideSnackbar: () => {
      const snackbarStore = useSnackbarStore();
      return snackbarStore.hideSnackbar;
    },
  },
});
