import createClient from 'openapi-fetch';

import { type paths as authPaths, type components as authComponents } from './openapi/authService';

const BASE_URL = import.meta.env.DEV ? '' : import.meta.env.VITE_BACKEND_URL;

const clientsConfig = {
  baseUrl: BASE_URL,
  init: {
    headers: {},
    credentials: 'include',
  },
};

const fetcher = {
  auth: createClient<authPaths>(clientsConfig),
};

type IComponentsProto = { schemas: object };
type UAvailableComponents<T extends IComponentsProto> = keyof T['schemas'];

export type IOEntity<
  components extends IComponentsProto,
  component extends UAvailableComponents<components>,
> = components['schemas'][component];
// type IError = {
//   title: string;
//   status: number;

//   detail: IError | string;

//   instance?: string;
//   errors?: Record<string, unknown>;
// };

type IOAuthEntity<component extends UAvailableComponents<authComponents>> = IOEntity<authComponents, component>;
export { fetcher, type IOAuthEntity };

/* DEMO
;( async () => {
  const res: IORes<'Template'> = await fetcher.path( '/Template' ).method( 'post' ).create()( {
    name: 'sd',
    templateTypeId: 1,
    body: 'sdsds',
  } )

  const template: IOEntity<'Template'> = res.data

} )()
*/
