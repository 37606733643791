import type { RouteRecordRaw } from 'vue-router';
import { notFoundGuard } from '@app/guards/notFound';

const name = 'NotFound';

export const route: RouteRecordRaw = {
  path: '/not-found',
  name,
  redirect: '/',
  beforeEnter: [notFoundGuard],
};

export const page = {
  _name: name,
} as const;
