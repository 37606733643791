import type { RouteRecordRaw } from 'vue-router';

import { route as testingRoute, page as testing } from './testing';
import { route as rootRoute, page as root } from './root';
import { route as demoRoute, page as demo } from './demo';
import { route as authRoute, page as auth } from './auth';
import { route as notFoundRoute, page as notFound } from './notFound';

export const routes: readonly RouteRecordRaw[] = [
  testingRoute,
  demoRoute,
  authRoute,
  rootRoute,
  notFoundRoute,
] as const;

export const pages = {
  _name: null,
  root,
  testing,
  demo,
  auth,
  notFound,
} as const;
