import type { RouteRecordRaw } from 'vue-router';

import { route as subExampleRoute, page as subexample } from './subexample';

const name = 'Example';

export const route: RouteRecordRaw = {
  path: 'example',
  name,
  component: () => import('@/pages/example'),
  beforeEnter: [],
  children: [subExampleRoute],
};

export const page = {
  _name: name,
  subexample,
} as const;
