import { SessionModel, SessionApi } from '@/entities/session';

// TODO Написать реальную реализацюи, когда будет больше логики, пока это скорее для демонстрации
export const useAuth = () => {
  const sessionStore = SessionModel.useSessionStore();

  const loadSessionUserTasks = async () => {};
  const loadSessionUserLeads = async () => {};
  const loadSessionUser = async () => {
    await SessionApi.api.isAuthed();
  };

  const loadStoresData = async () => {
    if (sessionStore.sessionDataLoaded) {
      return;
    }

    await Promise.allSettled([loadSessionUser(), loadSessionUserTasks(), loadSessionUserLeads()]);

    sessionStore.sessionDataLoaded = true;
  };

  return {
    loadStoresData,
  };
};
