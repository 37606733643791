import { type ILoggerMessage, SPLITTER } from '@shared/libs/logger';

export class InternalError extends Error implements ILoggerMessage {
  name = 'InternalError';
  serialize() {
    return {
      title: 'Внутреняя ошибка',
      message: `error.message: "${this.message}";
${SPLITTER}
error.cause: "${this.cause}";
${SPLITTER}
error.stack:
${this.stack}`,
    };
  }
}
