import type { RouteRecordRaw } from 'vue-router';
import { onInitGuard } from '@app/guards/onInit';
const name = 'Demo';

export const route: RouteRecordRaw = {
  path: '/demo',
  name,
  component: () => import('@/pages/demo'),
  beforeEnter: [onInitGuard],
  children: [],
};

export const page = {
  _name: name,
} as const;
