import { vuetify } from './plugins/vuetify';
import App from './App.vue';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { router } from './router';

import '@shared/styles/global.scss';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

app.use(vuetify);

app.mount('#app');
