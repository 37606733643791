import { defineStore } from 'pinia';
import { generateId } from '@shared/libs/generate';

const TOAST_DEFAULT_LIFE_TIME = 5000;

export type UToastTypes = 'warn' | 'error' | 'success' | 'message';

type IToastIn = {
  title: string;
  subtitle?: string;
  message?: string;
  life?: number;
};

type IToastInternalContainer = IToastIn & {
  type: UToastTypes;
  life: number;
};

export type IToast = IToastInternalContainer & {
  id: number;
};

export const useToastStore = defineStore('toast', {
  state: () => ({
    toasts: [] as IToast[],
  }),
  actions: {
    _addToast(toast: IToastInternalContainer) {
      const id = generateId();
      this.toasts.push({
        ...toast,
        id,
      });
      return id;
    },
    showToast(): Record<UToastTypes, (toastIn: IToastIn) => number> {
      return {
        message: (toastIn) => {
          return this._addToast({
            life: TOAST_DEFAULT_LIFE_TIME,
            ...toastIn,
            type: 'message',
          });
        },
        error: (toastIn) => {
          return this._addToast({
            life: TOAST_DEFAULT_LIFE_TIME,
            ...toastIn,
            type: 'error',
          });
        },
        success: (toastIn) => {
          return this._addToast({
            life: TOAST_DEFAULT_LIFE_TIME,
            ...toastIn,
            type: 'success',
          });
        },
        warn: (toastIn) => {
          return this._addToast({
            life: TOAST_DEFAULT_LIFE_TIME,
            ...toastIn,
            type: 'warn',
          });
        },
      };
    },
    hideToast(toastId: number) {
      const toastInx = this.toasts.findIndex((toast) => toast.id === toastId);
      if (typeof toastInx === 'number') {
        this.toasts.splice(toastInx, 1);
      }
    },
  },
});
