import type { RouteRecordRaw } from 'vue-router';

const name = 'SubexamplePage';

export const route: RouteRecordRaw = {
  path: 'subexample',
  name,
  component: () => import('@/pages/testing'),
  beforeEnter: [],
  children: [],
};

export const page = {
  _name: name,
} as const;
