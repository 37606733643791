import { provide, inject, type InjectionKey } from 'vue';
import { type pages as appPages } from '@/app/router/routes';

import { injectError, internalError } from '@shared/errors/index';

export const EProviders = {
  PAGES_PROVIDER: Symbol() as InjectionKey<typeof appPages>,
};

let isProvidersAlreadyUsed = false;
export const useProvidersAtProject = (pages: typeof appPages) => {
  if (isProvidersAlreadyUsed) {
    throw internalError('Провайдеры уже были инициализированы!');
  }

  provide(EProviders.PAGES_PROVIDER, pages);
  isProvidersAlreadyUsed = true;
};

export const usePagesProvider = () => {
  const appPages = inject(EProviders.PAGES_PROVIDER);

  if (!appPages) {
    throw injectError('AppPages');
  }

  return { appPages };
};
