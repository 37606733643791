import type { RouteRecordRaw } from 'vue-router';

import { authGuard } from '@app/guards/auth';
import { onInitGuard } from '@app/guards/onInit';

import { route as exampleRoute, page as example } from './example';
import { route as homeRoute, page as home } from './home';

const name = 'Root';

export const route: RouteRecordRaw = {
  path: '/',
  name,
  beforeEnter: [authGuard, onInitGuard],
  meta: {
    authed: true,
  },
  redirect: { name: home._name },
  children: [exampleRoute, homeRoute],
};

export const page = {
  _name: name,
  example,
  home,
} as const;
