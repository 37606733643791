import { type NavigationGuard } from 'vue-router';
import { useAuth } from '@/features/auth';
import { SessionModel } from '@/entities/session';
import { hidePreloader } from '@shared/libs/preloader';

export const onInitGuard: NavigationGuard = async (to, from, next) => {
  const { loadStoresData } = useAuth();

  const sessionStore = SessionModel.useSessionStore();

  if (sessionStore.signedIn) {
    await loadStoresData();
  }

  hidePreloader();
  next();
};
