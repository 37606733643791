let hidden = false;
export const hidePreloader = () => {
  if (hidden) {
    return;
  }

  const $preloader = document.querySelector('#preloader') as HTMLElement;
  if ($preloader) {
    $preloader.addEventListener('transitionend', () => {
      document.body.removeChild($preloader);
    });
    $preloader.style.opacity = '0';
  }

  hidden = true;
};
